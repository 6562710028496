import { ToastContainer } from "react-toastify"
import Button from "../buttons/Button"
import Icon from "../icon/Icon"

const ToastsRoot = () => {
  return (
    <ToastContainer
      className="mt-10"
      closeButton={({ closeToast }) => (
        <Button
          aria-label="Close"
          className="ml-xs h-fit"
          color="tertiary"
          shape="uncontain"
          size="small"
          title="Close"
          type="button"
          onClick={closeToast}
        >
          <Icon icon="cross-big" size={16} type="solid" />
        </Button>
      )}
      closeOnClick={false}
      hideProgressBar
      icon={false}
      pauseOnFocusLoss
      pauseOnHover
      theme="colored"
    />
  )
}

export default ToastsRoot
