import { Link, LinkProps } from "@tanstack/react-location"
import clsx from "clsx"
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode
} from "react"
import Icon, { IconSolidName } from "../icon/Icon"

interface BaseProps {
  icon?: IconSolidName
}

export type ButtonAsButton = BaseProps &
  ButtonHTMLAttributes<HTMLButtonElement> & { type: "button" }
export type ButtonAsAnchor = BaseProps &
  AnchorHTMLAttributes<HTMLAnchorElement> & { type: "anchor"; disabled?: boolean }
export type ButtonAsLink = BaseProps &
  Omit<LinkProps, "children"> & { type: "link"; children: ReactNode }
export type Props = ButtonAsButton | ButtonAsAnchor | ButtonAsLink

const CardButton: ForwardRefRenderFunction<null, Props> = (props, ref) => {
  const cls = clsx(
    "inline-flex items-center gap-4 border border-grey-300 p-4 text-left text-grey-800 radius-xs",
    props.disabled ? "cursor-not-allowed opacity-50" : "hover:border-grey-400 hover:bg-grey-100",
    props.className
  )

  if (props.type === "anchor") {
    const { className, children, icon, disabled, type, ...attrs } = props
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
      <a
        ref={ref}
        className={cls}
        onClick={disabled ? e => e.preventDefault() : undefined}
        {...attrs}
      >
        {icon && <Icon icon={icon} size={20} type="solid" />}
        <div className="grow">{children}</div>
      </a>
    )
  }
  if (props.type === "link") {
    const { className, children, icon, type, ...attrs } = props
    return (
      <Link _ref={ref} className={cls} {...attrs}>
        {icon && <Icon icon={icon} size={20} type="solid" />}
        <div className="grow">{children}</div>
      </Link>
    )
  }

  const { className, children, icon, ...attrs } = props
  return (
    <button ref={ref} className={cls} {...attrs}>
      {icon && <Icon icon={icon} size={20} type="solid" />}
      <div className="grow">{children}</div>
    </button>
  )
}

export default forwardRef(CardButton)
