import { useSuspenseQuery } from "@tanstack/react-query"
import { useMatchesParams } from "src/hooks/router"
import {
  getQueryKey,
  useGetQuery,
  usePatchQuery,
  usePostQuery,
  useRouteParams
} from "src/queries/helpers"
import {
  getOrganizationMemberListUrl,
  getOrganizationMemberUrl
} from "src/queries/organizationMembers"
import { OrgParams } from "src/queries/types"
import { queryClient } from "./client"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/**
 * Note: the orgUuid is not required because this endpoint is used for invitations, where users do
 * not belong to any organization yet.
 * @returns /users/me/?org=${orgUuid}
 */
export const getMeUrl = ({ orgUuid }: OrgParams) => `/users/me/?org=${orgUuid}`

/** @returns /users/me/reset_password/?org=${orgUuid} */
export const getResetMyPasswordUrl = ({ orgUuid }: Required<OrgParams>) =>
  `/users/me/reset_password/?org=${orgUuid}`

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const useMe = (params?: OrgParams) => {
  const mergedParams = useRouteParams(params)
  const url = getMeUrl(mergedParams)

  return useGetQuery<api.user.Self>("user", url)
}

export const useUpdateMe = (params?: OrgParams) => {
  const mergedParams = useRouteParams(params)
  const url = getMeUrl(mergedParams)
  const memberListUrl = getOrganizationMemberListUrl(mergedParams)

  type TRequest = DeepPartial<api.user.Self>
  type TResponse = api.user.Self

  return usePatchQuery<TRequest, TResponse>("user", url, [memberListUrl], {
    onSuccess: async data => {
      await queryClient.refetchQueries({
        queryKey: getQueryKey(
          "user",
          getOrganizationMemberUrl({ ...mergedParams, userUuid: data.uuid })
        )
      })
    }
  })
}

export const useResetMyPassword = (params?: OrgParams) => {
  const mergedParams = useRouteParams(params)
  const url = getResetMyPasswordUrl(mergedParams)

  return usePostQuery("user", url, [])
}

export const useRefreshMe = () => {
  const { data: me } = useSuspenseQuery(useMe())
  const { orgUuid } = useMatchesParams() as Required<OrgParams>
  const meUrl = getMeUrl({ orgUuid })
  const orgMemberListUrl = getOrganizationMemberListUrl({ orgUuid })
  const orgMemberUrl = getOrganizationMemberUrl({ orgUuid, userUuid: me.uuid })

  return () =>
    Promise.all([
      queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", meUrl) }),
      queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", orgMemberUrl) }),
      queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", orgMemberListUrl) })
    ])
}
