import { merge } from "lodash-es"
import { createContext, FC, ReactNode, useContext, useState } from "react"
import ModalConfirmation, {
  ModalConfirmationProps
} from "src/components/base/modals/ModalConfirmation"

export interface OpenModalConfirmationProps
  extends Omit<ModalConfirmationProps, "show" | "onClose"> {
  onClose?: () => void
}

interface ModalConfirmationContextProps {
  openConfirmationModal: (props: OpenModalConfirmationProps) => void
}

const ModalConfirmationContext = createContext<ModalConfirmationContextProps>({
  openConfirmationModal: () => undefined
})

interface ModalConfirmationProviderProps {
  children: ReactNode
}

const ModalConfirmationProvider: FC<ModalConfirmationProviderProps> = ({
  children
}: ModalConfirmationProviderProps) => {
  const initialState: ModalConfirmationProps = {
    cancelBtnLabel: undefined,
    children: undefined,
    confirmValue: undefined,
    message: undefined,
    okBtnLabel: undefined,
    onAccept(): void {},
    onClose(): void {},
    show: false,
    title: undefined
  }
  const [confirmationModalProps, setConfirmationModalProps] =
    useState<ModalConfirmationProps>(initialState)

  return (
    <ModalConfirmationContext.Provider
      value={{
        openConfirmationModal: props => {
          const modalProps = merge(initialState, props)

          setConfirmationModalProps({
            ...modalProps,
            onClose: () => {
              setConfirmationModalProps({
                ...initialState
              })
              if (props.onClose) {
                props.onClose()
              }
            },
            ...(props.onGoBack && {
              onGoBack: () => {
                setConfirmationModalProps({
                  ...initialState
                })
                if (props.onGoBack) {
                  props.onGoBack()
                }
              }
            }),
            show: true
          })
        }
      }}
    >
      <ModalConfirmation {...confirmationModalProps} />
      {children}
    </ModalConfirmationContext.Provider>
  )
}

const useModalConfirmation = () => useContext(ModalConfirmationContext)

export { ModalConfirmationProvider, useModalConfirmation }
