import clsx from "clsx"
import { FC, HTMLAttributes, PropsWithChildren } from "react"

interface Props extends HTMLAttributes<HTMLHeadingElement>, PropsWithChildren {}

const Title3: FC<Props> = ({ children, className, ...attrs }) => (
  <h3 className={clsx("text-size-3 font-bold leading-[1.125]", className)} {...attrs}>
    {children}
  </h3>
)
export default Title3
