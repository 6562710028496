import { QueryFunctionContext } from "@tanstack/react-query"
import { jsonGet } from "../services/api"

interface Release {
  build: {
    date: string
    id?: string
  }
}

export const useRelease = () => {
  const url = "/release.json"

  return {
    queryFn: ({ signal }: QueryFunctionContext) => jsonGet<Release>(url, "", "www", { signal }),
    queryKey: ["release"]
  }
}
