import { Navigate, useMatch } from "@tanstack/react-location"
import { FC } from "react"

interface Props {
  to: string
}

const Redirect: FC<Props> = ({ to }) => {
  const { params } = useMatch()
  const url = to
    .split("/")
    .map(token => (token.startsWith(":") ? params[token.slice(1)] : token))
    .join("/")

  return <Navigate replace search={old => ({ ...old })} to={url} />
}

export default Redirect
