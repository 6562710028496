import { MakeGenerics } from "@tanstack/react-location"
import { useStore } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"
import { createStore } from "zustand/vanilla"

export type ImpersonificationGenerics = MakeGenerics<{
  Search: { backOfficeUserUuid: string }
}>

interface State {
  operatorUuid?: string
  clear: () => void
}

const getOperatorUuid = () => {
  const { search } = window.location
  const urlSearch = new URLSearchParams(search)
  return urlSearch.get("backOfficeUserUuid") || undefined
}

export const impersonificationStore = createStore<State>()(
  persist(
    set => ({
      clear: () => set({ operatorUuid: undefined }),
      operatorUuid: getOperatorUuid()
    }),
    {
      name: "impersonification",
      storage: createJSONStorage(() => sessionStorage)
    }
  )
)

export const useImpersonificationStore = <T>(selector: (state: State) => T) =>
  useStore(impersonificationStore, selector)
