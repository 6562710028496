import { useMemo } from "react"
import { SimpleDestination } from "src/queries/linkDestinations"
import { SimpleSource } from "src/queries/linkSources"

export const useStaticNameSenders = (source: api.link.SourceResponse | SimpleSource) => {
  const getName = (
    availabilityMode: api.link.SourceResponse["availabilityMode"],
    index: number
  ) => {
    const names = {
      dual_link: ["Sender 1", "Sender 2"],
      dual_link_with_failover: ["Main sender", "Failover sender"],
      single_link: ["Sender"],
      single_link_with_failover: ["Main sender", "Failover sender"]
    }

    return names[availabilityMode][index] || "Sender"
  }

  return useMemo(
    () =>
      source.senders.map((sender, senderIndex) => ({
        ...sender,
        name: getName(source.availabilityMode, senderIndex),
        streams: sender.streams?.map((stream, streamIndex) => ({
          ...stream,
          name: `Ingest point #${streamIndex + 1}`
        }))
      })),
    [source]
  )
}

export const useStaticNameReceivers = (
  destination: api.link.DestinationResponse | SimpleDestination
) => {
  const getName = (
    availabilityMode: api.link.DestinationResponse["availabilityMode"],
    index: number
  ) => {
    const names = {
      dual_link: ["Receiver 1", "Receiver 2"],
      single_link: ["Receiver"]
    }

    return names[availabilityMode][index] || "Receiver"
  }

  return useMemo(
    () =>
      destination.receivers.map((receiver, receiverIndex) => ({
        ...receiver,
        name: getName(destination.availabilityMode, receiverIndex),
        streams: receiver.streams?.map((stream, streamIndex) => ({
          ...stream,
          name: `Delivery point #${streamIndex + 1}`
        }))
      })),
    [destination]
  )
}
