import clsx from "clsx"

export const capitalize = (text: string) =>
  text ? text.slice(0, 1).toUpperCase() + text.slice(1) : ""

// See https://stackoverflow.com/a/21015393/1202385

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
// re-use canvas object for better performance
let canvas: HTMLCanvasElement

export const getCssStyle = (el: HTMLElement, property: string) =>
  window.getComputedStyle(el, null).getPropertyValue(property)

export const getCanvasFont = (className: string) => {
  const el = document.createElement("div")
  el.className = clsx(className, "pointer-events-none invisible fixed left-0 top-0")
  try {
    document.body.appendChild(el)
    const fontWeight = getCssStyle(el, "font-weight") || "normal"
    const fontSize = getCssStyle(el, "font-size") || "16px"
    const fontFamily = getCssStyle(el, "font-family") || "Times New Roman"
    return `${fontWeight} ${fontSize} ${fontFamily}`
  } finally {
    document.body.removeChild(el)
  }
}

export const getTextWidth = (text: string, font: string) => {
  canvas = canvas || document.createElement("canvas")
  const context = canvas.getContext("2d")
  if (!context) throw new Error("Cannot get Canvas context")
  context.font = font
  const metrics = context.measureText(text)
  return Math.ceil(metrics.width)
}

// Example:
// getTextWidth("hello there!", "bold 12pt arial")
// getTextWidth(text, getCanvasFont())
// getTextWidth(text, getCanvasFont(myEl))
