import { useApi } from "src/hooks/api"
import { queryClient } from "./client"
import { getQueryKey, useDeleteQuery, useGetQuery, usePatchQuery, useRouteParams } from "./helpers"
import { OrgParams, UserParams } from "./types"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /organization/invitation_request/?org=${orgUuid} */
export const getOrganizationInvitationRequestListUrl = ({ orgUuid }: Required<OrgParams>) =>
  `/organization/invitation_request/?org=${orgUuid}`

/** @returns /organization/members/?org=${orgUuid} */
export const getOrganizationMemberListUrl = ({ orgUuid }: Required<OrgParams>) =>
  `/organization/members/?org=${orgUuid}`

/** @returns /organization/members/${userUuid}/?org=${orgUuid} */
export const getOrganizationMemberUrl = ({ orgUuid, userUuid }: Required<UserParams>) =>
  `/organization/members/${userUuid}/?org=${orgUuid}`

/** @returns /organization/members/${userUuid}/validate/?org=${orgUuid} */
export const getOrganizationMemberValidationUrl = ({ orgUuid, userUuid }: Required<UserParams>) =>
  `/organization/members/${userUuid}/validate/?org=${orgUuid}`

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const useOrganizationMemberList = (params?: OrgParams) => {
  const mergedParams = useRouteParams(params)
  const listUrl = getOrganizationMemberListUrl(mergedParams)

  return useGetQuery<api.user.PaginatedMemberList>("user", listUrl)
}

export const useOrganizationMember = (params?: UserParams) => {
  const mergedParams = useRouteParams(params)
  const url = getOrganizationMemberUrl(mergedParams)

  return useGetQuery<api.user.MemberDetail>("user", url)
}

export const useAddOrganizationMemberList = (params?: OrgParams) => {
  const { jsonPost } = useApi("user")
  const mergedParams = useRouteParams(params)
  const listUrl = getOrganizationMemberListUrl(mergedParams)

  type TRequest = api.user.MemberCreationRequest
  type TResponse = api.user.Member

  return {
    mutationFn: (dataList: TRequest[]) =>
      Promise.all(dataList.map(data => jsonPost<TResponse>(listUrl, data))),
    onSuccess: async (dataList: TResponse[]) => {
      for (const data of dataList) {
        const url = getOrganizationMemberUrl({ ...mergedParams, userUuid: data.uuid })
        queryClient.setQueryData(getQueryKey("user", url), data)
      }
      await queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", listUrl) })
    }
  }
}

export const useUpdateOrganizationMember = (params?: UserParams) => {
  const mergedParams = useRouteParams(params)
  const url = getOrganizationMemberUrl(mergedParams)
  const listUrl = getOrganizationMemberListUrl(mergedParams)

  type TRequest = DeepPartial<api.user.Member>
  type TResponse = api.user.Member

  return usePatchQuery<TRequest, TResponse>("user", url, [listUrl])
}

export const useUpdateOrganizationMembers = (params?: OrgParams) => {
  const { jsonPatch } = useApi("user")
  const mergedParams = useRouteParams(params)
  const listUrl = getOrganizationMemberListUrl(mergedParams)

  type TRequest = DeepPartial<api.user.Member>
  type TResponse = api.user.Member

  return {
    mutationFn: (dataList: TRequest[]) =>
      Promise.all(
        dataList.map(data =>
          jsonPatch<TResponse>(
            getOrganizationMemberUrl({ ...mergedParams, userUuid: `${data.uuid}` }),
            data
          )
        )
      ),
    onSuccess: async (dataList: TResponse[]) => {
      for (const data of dataList) {
        const url = getOrganizationMemberUrl({ ...mergedParams, userUuid: data.uuid })
        queryClient.setQueryData(getQueryKey("user", url), data)
      }
      await queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", listUrl) })
    }
  }
}

export const useDeleteOrganizationMember = (params?: UserParams) => {
  const mergedParams = useRouteParams(params)
  const url = getOrganizationMemberUrl(mergedParams)
  const listUrl = getOrganizationMemberListUrl(mergedParams)

  return useDeleteQuery("user", url, [listUrl])
}

export const useAddInvitationRequestList = (params?: OrgParams) => {
  const { jsonPost } = useApi("user")
  const mergedParams = useRouteParams(params)
  const listUrl = getOrganizationInvitationRequestListUrl(mergedParams)
  const organizationMemberListUrl = getOrganizationMemberListUrl(mergedParams)

  type TRequest = api.user.MemberCreationRequest
  type TResponse = api.user.Member

  return {
    mutationFn: (dataList: TRequest[]) =>
      Promise.all(dataList.map(data => jsonPost<TResponse>(listUrl, data))),
    onSuccess: async (dataList: TResponse[]) => {
      for (const data of dataList) {
        const url = getOrganizationMemberUrl({ ...mergedParams, userUuid: data.uuid })
        queryClient.setQueryData(getQueryKey("user", url), data)
      }
      await queryClient.refetchQueries({
        exact: true,
        queryKey: getQueryKey("user", organizationMemberListUrl)
      })
    }
  }
}

export const useValidateOrganizationMember = (params?: UserParams) => {
  const { jsonPost } = useApi("user")
  const mergedParams = useRouteParams(params)
  const memberUrl = getOrganizationMemberUrl(mergedParams)
  const memberListUrl = getOrganizationMemberListUrl(mergedParams)
  const validationUrl = getOrganizationMemberValidationUrl(mergedParams)

  return {
    mutationFn: () => jsonPost(validationUrl, {}),
    onSuccess: async () => {
      await Promise.all([
        queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", memberUrl) }),
        queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", memberListUrl) })
      ])
    }
  }
}
