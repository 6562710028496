import clsx from "clsx"
import { HTMLAttributes, forwardRef, ForwardRefRenderFunction } from "react"

interface Props extends HTMLAttributes<HTMLElement> {
  as?: keyof HTMLElementTagNameMap
}

const Panel: ForwardRefRenderFunction<null, Props> = (
  { as: Element = "div", className, ...props },
  ref
) => (
  <Element
    // @ts-ignore using ref seems to produce a too complex type for typescript
    ref={ref}
    className={clsx(
      "bg-white p-8 outline outline-2 outline-grey-200 radius-xs",
      "peer-panel [&.peer-panel+.peer-panel]:mt-6",
      "dark:bg-grey-900 dark:text-grey-100 dark:outline-none",
      props.inert !== undefined &&
        "user-select-none pointer-events-none cursor-not-allowed opacity-30",
      className
    )}
    {...props}
  />
)

export default forwardRef(Panel)
