import clsx from "clsx"
import { FC } from "react"
import Icon, { IconSize } from "./Icon"

interface LoadingIconProps {
  className?: string
  size?: IconSize
  type?: "regular" | "solid"
}

const LoadingIcon: FC<LoadingIconProps> = ({ className, size = 20, type = "solid" }) => (
  <Icon className={clsx("animate-spin", className)} icon="rotate" size={size} type={type} />
)

export default LoadingIcon
