/* eslint-disable sort-keys-fix/sort-keys-fix */
import { DefaultGenerics, Route } from "@tanstack/react-location"
import { lazy } from "react"
import { NotFoundError } from "src/helpers/errors"

// Misc
const App = lazy(() => import("./App"))

// Main
const Main = lazy(() => import("./App/Main"))
const Pool = lazy(() => import("./App/Main/Pool"))
const DashboardV2Wrapper = lazy(
  () => import("./App/Main/Pool/dashboard/Dashboard/DashboardV2Wrapper")
)
const EventLog = lazy(() => import("./App/Main/Pool/eventlog/EventLog"))

// Source
const SourceView = lazy(() => import("./App/Main/Pool/sources/SourceView/Legacy"))
const SourceList = lazy(() => import("./App/Main/Pool/sources/SourcesList/Legacy"))
const LegacySourceOverviewTab = lazy(
  () => import("./App/Main/Pool/sources/SourceView/Legacy/OverviewTab")
)
const LegacySourceConfigurationTab = lazy(
  () => import("./App/Main/Pool/sources/SourceView/Legacy/ConfigurationTab")
)
const LegacyLinksTab = lazy(() => import("./App/Main/Pool/sources/SourceView/Legacy/LinksTab"))
const LegacyRedundancyTab = lazy(
  () => import("./App/Main/Pool/sources/SourceView/Legacy/RedundancyTab")
)
const LegacySourceStreamEdit = lazy(
  () => import("./App/Main/Pool/sources/SourceView/Legacy/ConfigurationTab/StreamEdit")
)
const LegacySendersEdit = lazy(
  () => import("./App/Main/Pool/sources/SourceView/Legacy/ConfigurationTab/SendersEdit")
)

// Input
const InputList = lazy(() => import("./App/Main/Pool/input/InputList"))
const SourceOverviewTab = lazy(() => import("./App/Main/Pool/input/InputView/OverviewTab"))
const InputView = lazy(() => import("./App/Main/Pool/input/InputView"))
const InputConfigurationTab = lazy(() => import("./App/Main/Pool/input/InputView/ConfigurationTab"))
const LinksTab = lazy(() => import("./App/Main/Pool/input/InputView/LinksTab"))
const StreamSwitchTab = lazy(() => import("./App/Main/Pool/input/InputView/StreamSwitchTab"))
const InputStreamEdit = lazy(
  () => import("./App/Main/Pool/input/InputView/ConfigurationTab/InputStreamEdit")
)

// Destination
const LegacyDestinationView = lazy(
  () => import("./App/Main/Pool/destinations/DestinationView/Legacy/")
)
const LegacyDestinationsList = lazy(
  () => import("./App/Main/Pool/destinations/DestinationsList/Legacy/")
)
const LegacyDestinationOverviewTab = lazy(
  () => import("./App/Main/Pool/destinations/DestinationView/Legacy/OverviewTab")
)
const LegacyDestinationSourceTab = lazy(
  () => import("./App/Main/Pool/destinations/DestinationView/Legacy/SourceTab")
)
const LegacyDestinationConfigurationTab = lazy(
  () => import("./App/Main/Pool/destinations/DestinationView/Legacy/ConfigurationTab")
)
const LegacyDestinationStreamEdit = lazy(
  () => import("./App/Main/Pool/destinations/DestinationView/Legacy/ConfigurationTab/StreamEdit")
)
const LegacyTranscodingTab = lazy(
  () => import("./App/Main/Pool/destinations/DestinationView/Legacy/TranscodingTab")
)
const LegacyReceiversEdit = lazy(
  () => import("./App/Main/Pool/destinations/DestinationView/Legacy/ConfigurationTab/ReceiversEdit")
)

// Output
const OutputView = lazy(() => import("./App/Main/Pool/output/OutputView"))
const OutputList = lazy(() => import("./App/Main/Pool/output/OutputList"))
const OutputOverviewTab = lazy(() => import("./App/Main/Pool/output/OutputView/OverviewTab"))
const OutputLinkTab = lazy(() => import("./App/Main/Pool/output/OutputView/LinkedInputTab"))
const OutputConfigurationTab = lazy(
  () => import("./App/Main/Pool/output/OutputView/ConfigurationTab")
)
const TranscodingTab = lazy(() => import("./App/Main/Pool/output/OutputView/TranscodingTab"))

// Others
const SchedulerV2Wrapper = lazy(() => import("./App/Main/Pool/scheduler/SchedulerV2Wrapper"))
const SchedulerEventDetailsV2Wrapper = lazy(
  () => import("./App/Main/Pool/scheduler/SchedulerEventDetailsV2Wrapper")
)

const Settings = lazy(() => import("./App/Main/Pool/settings/Settings"))
const AddPoolApiKey = lazy(() => import("./App/Main/Pool/settings/Settings/apiKeys/AddApiKey"))
const EditPoolApiKey = lazy(() => import("./App/Main/Pool/settings/Settings/apiKeys/EditApiKey"))
const Templates = lazy(() => import("./App/Main/Pool/templates"))

// Wizard
const Step1Source = lazy(() => import("./App/LegacyWizard/steps/Step1Source"))
const Step2Redundancy = lazy(() => import("./App/LegacyWizard/steps/Step2Redundancy"))
const Step3Destinations = lazy(() => import("./App/LegacyWizard/steps/Step3Destinations"))
const LegacyWizard = lazy(() => import("./App/LegacyWizard"))

// New Pool
const NewPool = lazy(() => import("./App/NewPool"))

const ThrowNotFound = () => {
  throw new NotFoundError()
}

export const linkRoutes: Route<DefaultGenerics> = {
  path: "link",
  element: <App />,
  children: [
    {
      path: "pools",
      element: <Main />,
      children: [
        {
          path: ":poolUuid",
          element: <Pool />,
          children: [
            { path: "/", element: <DashboardV2Wrapper /> },
            {
              path: "destinations/:destinationUuid",
              element: <LegacyDestinationView />,
              children: [
                {
                  path: "overview",
                  element: <LegacyDestinationOverviewTab />
                },
                {
                  path: "transcoding",
                  element: <LegacyTranscodingTab />
                },
                {
                  path: "source",
                  element: <LegacyDestinationSourceTab />
                },
                {
                  path: "configuration",
                  element: <LegacyDestinationConfigurationTab />,
                  children: [
                    {
                      path: ":receiverUuid/:streamUuid",
                      element: <LegacyDestinationStreamEdit />
                    },
                    {
                      path: "receivers",
                      element: <LegacyReceiversEdit />
                    }
                  ]
                }
              ]
            },
            { path: "destinations", element: <LegacyDestinationsList /> },
            {
              path: "outputs/:outputUuid",
              element: <OutputView />,
              children: [
                {
                  path: "overview",
                  element: <OutputOverviewTab />
                },
                {
                  path: "transcoding",
                  element: <TranscodingTab />
                },
                {
                  path: "link",
                  element: <OutputLinkTab />
                },
                {
                  path: "configuration",
                  element: <OutputConfigurationTab />
                }
              ]
            },
            { path: "outputs", element: <OutputList /> },
            {
              path: "scheduler",
              children: [
                { path: "/", element: <SchedulerV2Wrapper /> },
                {
                  path: "edit_event/:scheduledEventUuid",
                  element: <SchedulerEventDetailsV2Wrapper />
                }
              ]
            },
            { path: "settings/apikeys/new", element: <AddPoolApiKey /> },
            { path: "settings/apikeys/:apiKeyUuid", element: <EditPoolApiKey /> },
            { path: "settings", element: <Settings /> },
            { path: "eventlog", element: <EventLog /> },
            {
              path: "sources/:sourceUuid",
              element: <SourceView />,
              children: [
                {
                  path: "links",
                  element: <LegacyLinksTab />
                },
                {
                  path: "overview",
                  element: <LegacySourceOverviewTab />
                },
                {
                  path: "redundancy",
                  element: <LegacyRedundancyTab />
                },
                {
                  path: "configuration",
                  element: <LegacySourceConfigurationTab />,
                  children: [
                    {
                      path: ":senderUuid/:streamUuid",
                      element: <LegacySourceStreamEdit />
                    },
                    {
                      path: "senders",
                      element: <LegacySendersEdit />
                    }
                  ]
                }
              ]
            },
            {
              path: "inputs/:inputUuid",
              element: <InputView />,
              children: [
                {
                  path: "links",
                  element: <LinksTab />
                },
                {
                  path: "overview",
                  element: <SourceOverviewTab />
                },
                {
                  path: "switch",
                  element: <StreamSwitchTab />
                },
                {
                  path: "configuration",
                  element: <InputConfigurationTab />,
                  children: [
                    {
                      path: ":streamUuid",
                      element: <InputStreamEdit />
                    }
                  ]
                }
              ]
            },
            { path: "sources", element: <SourceList /> },
            { path: "inputs", element: <InputList /> },
            { path: "templates", element: <Templates /> }
          ]
        }
      ]
    },
    {
      path: "new_pool",
      element: <NewPool />
    },
    {
      path: "wizard/:poolUuid",
      element: <LegacyWizard />,
      children: [
        { path: "source", element: <Step1Source /> },
        { path: "redundancy", element: <Step2Redundancy /> },
        { path: "destinations", element: <Step3Destinations /> },
        { element: <ThrowNotFound /> }
      ]
    }
  ]
}
