import { captureException } from "@sentry/core"
import { FC, useEffect, useState } from "react"
import Divider from "src/components/base/misc/Divider"
import { copyToClipboard } from "src/helpers/clipboard"
import { ApiError } from "src/services/api"
import Button from "../buttons/Button"
import Hypertext from "../buttons/Hypertext"
import Icon from "../icon/Icon"

interface Props {
  error: any
}

const ApiErrorToast: FC<Props> = ({ error }) => {
  const [isShowDetails, setIsShowDetails] = useState(false)

  useEffect(() => {
    if (!(error instanceof ApiError)) captureException(error)
  }, [error])

  return (
    <>
      <p className="mb-2xs p-0 font-medium">
        {error instanceof ApiError
          ? error.message === "Failed to fetch"
            ? "API connection lost"
            : "API Error"
          : "Error"}
      </p>
      <p className="text-sm">
        {error.message === "Failed to fetch"
          ? "We cannot reach the API… Make sure your network connection is active."
          : "Oops, something got quite wrong"}
      </p>
      <p>
        <Hypertext
          as="button"
          className="mt-2 underline !shadow-none"
          size="small"
          onClick={() => {
            setIsShowDetails(!isShowDetails)
          }}
        >
          {isShowDetails ? "Hide details" : "Show details"}
        </Hypertext>
      </p>
      {isShowDetails && (
        <div className="w-[39rem]">
          <Divider className="mx-none my-xs h-px" color="error" />
          <div className="flex items-center">
            <div className="custom-scrollbar mt-4 max-h-80 overflow-y-scroll">
              {error instanceof ApiError ? (
                <span className="max-h-96 overflow-scroll font-monospace text-sm">
                  <p className="break-all">
                    {error.method} {error.uri}
                  </p>
                  <p>HTTP {error.status}</p>
                  <pre>{error.jsonData && JSON.stringify(error.jsonData, null, 2)}</pre>
                </span>
              ) : (
                <span className="font-monospace text-sm text-red-800">{error.message}</span>
              )}
            </div>
            <Button
              aria-label="Copy"
              className="ml-xs h-fit"
              color="tertiary"
              shape="uncontain"
              size="small"
              title="Close"
              type="button"
              onClick={() => {
                copyToClipboard(error.message)
              }}
            >
              <Icon icon="duplicate" size={24} type="regular" />
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default ApiErrorToast
