import { Link, LinkProps } from "@tanstack/react-location"
import clsx from "clsx"
import {
  ButtonHTMLAttributes,
  forwardRef,
  AnchorHTMLAttributes,
  ForwardRefRenderFunction
} from "react"
import LoadingIcon from "../icon/LoadingIcon"

export type ButtonSize = "small" | "medium" | "large" | "xlarge"
interface BaseProps {
  color: "danger" | "danger-outline" | "portal" | "primary" | "secondary" | "tertiary" | "brand"
  dropdownButton?: boolean
  size?: ButtonSize
  isActive?: boolean
  isLoading?: boolean
  "data-testid"?: string
  shape?: "icon" | "text" | "uncontain"
}
export type ButtonAsButton = BaseProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type"> & { type: "button" | "submit" }
export type ButtonAsAnchor = BaseProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "type"> & {
    type: "anchor"
    disabled?: boolean
  }
export type ButtonAsLink = BaseProps &
  Omit<LinkProps, "type"> & { type: "link"; disabled?: boolean }
export type ButtonProps = ButtonAsButton | ButtonAsAnchor | ButtonAsLink

const Button: ForwardRefRenderFunction<null, ButtonProps> = (props, ref) => {
  const {
    className,
    children,
    color,
    disabled,
    dropdownButton,
    isActive,
    isLoading,
    size,
    shape,
    type,
    ...attrs
  } = props

  const colorToCls = {
    brand: "border-transparent bg-transparent text-yellow-300", // shape uncontain only
    danger: clsx("border-transparent bg-red-600 text-white shadow-sm"),
    "danger-outline":
      shape === "icon"
        ? clsx("border-red-600 text-red-600 shadow-sm")
        : clsx("border-red-600 text-red-600 shadow-sm"),
    portal: clsx("border-transparent text-grey-100"),
    primary:
      shape === "icon"
        ? clsx("border-transparent text-grey-800")
        : shape === "uncontain"
          ? clsx("border-transparent bg-transparent text-grey-900")
          : clsx("border-transparent bg-yellow-300 text-grey-900 shadow-sm"),
    secondary:
      shape === "icon"
        ? clsx("border-transparent text-grey-600")
        : shape === "uncontain"
          ? clsx("border-transparent bg-transparent text-grey-600")
          : clsx("border-transparent bg-grey-200 text-grey-800 shadow-sm"),
    tertiary:
      shape === "uncontain"
        ? clsx("border-transparent bg-transparent text-grey-500")
        : clsx("border-transparent text-grey-800")
  }

  const colorToHoverCls = {
    brand: "hover:text-yellow-400", // shape uncontain only
    danger: clsx("hover:bg-red-800"),
    "danger-outline":
      shape === "icon"
        ? clsx("hover:border-red-800 hover:text-red-800")
        : clsx("hover:border-red-800 hover:text-red-800"),
    portal: clsx("hover:bg-grey-700"),
    primary:
      shape === "icon"
        ? clsx("hover:bg-grey-300")
        : shape === "uncontain"
          ? clsx("hover:text-grey-900")
          : clsx("hover:bg-yellow-400"),
    secondary:
      shape === "icon"
        ? clsx("hover:bg-grey-300 hover:text-grey-800")
        : shape === "uncontain"
          ? clsx("hover:text-grey-700")
          : clsx("hover:bg-grey-300"),
    tertiary: shape === "uncontain" ? clsx("hover:text-grey-600") : clsx("hover:bg-grey-300")
  }

  const colorToActiveCls: Partial<Record<BaseProps["color"], string>> = {
    brand: "text-yellow-400", // shape uncontain only
    danger: clsx("border-transparent bg-red-900 text-white shadow-sm"),
    "danger-outline": clsx("border-red-900 text-red-900 shadow-sm"),
    portal: clsx("border-transparent bg-grey-700 text-grey-100"),
    primary:
      shape === "icon"
        ? clsx("border-transparent bg-grey-300 text-grey-800 shadow-sm")
        : shape === "uncontain"
          ? clsx("border-transparent bg-transparent text-grey-900")
          : clsx(
              "border-transparent text-grey-900 shadow-sm",
              dropdownButton ? "bg-yellow-400" : "bg-yellow-500"
            ),
    secondary:
      shape === "uncontain"
        ? clsx("border-transparent bg-transparent text-grey-700")
        : clsx("border-transparent bg-grey-300 text-grey-800 shadow-sm"),
    tertiary:
      shape === "uncontain"
        ? clsx("border-transparent bg-transparent text-grey-600")
        : clsx("border-transparent bg-grey-300 text-grey-800")
  }

  const sizeToCls = {
    large: clsx("text-normal h-10 px-sm radius-xs"),
    medium: clsx("h-8 px-xs text-sm radius-xs"),
    small: clsx("h-6 px-xs text-xs leading-none radius-xs"),
    xlarge: clsx("h-14 px-md text-xl leading-none radius-xs")
  }

  const iconSizeToCls = {
    large: clsx("h-10 w-10 text-sm leading-none radius-xs"),
    medium: clsx("h-lg w-lg text-xs leading-none radius-xs"),
    small: clsx("h-md w-md text-xs leading-none radius-xs"),
    xlarge: clsx("h-14 w-14 text-sm leading-none radius-xs")
  }

  const isDisabled = disabled || isLoading
  const borderSizeSmall = size === "small" || size === "medium"

  const cls = clsx(
    "inline-flex cursor-pointer select-none items-center justify-center whitespace-nowrap border font-medium leading-none",
    size === "small" ? "gap-2xs" : "gap-xs",
    shape === "icon"
      ? iconSizeToCls[size || "large"]
      : shape !== "uncontain" && sizeToCls[size || "large"],
    isActive ? colorToActiveCls[color] : colorToCls[color],
    isDisabled ? " !cursor-not-allowed opacity-50" : colorToHoverCls[color],
    borderSizeSmall ? "border-1" : "border-2",
    className
  )

  if (type === "anchor") {
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
      <a
        ref={ref}
        className={cls}
        onClick={disabled ? e => e.preventDefault() : undefined}
        {...(attrs as ButtonAsAnchor)}
      >
        {children}
      </a>
    )
  }
  if (type === "link") {
    return (
      <Link _ref={ref} className={cls} disabled={disabled} {...(attrs as ButtonAsLink)}>
        {children}
      </Link>
    )
  }

  return (
    <button
      ref={ref}
      className={cls}
      disabled={isDisabled}
      type={type}
      {...(attrs as ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      {isLoading && <LoadingIcon size={size === "xlarge" ? 24 : size === "large" ? 20 : 16} />}
      {((!isLoading && shape === "icon") || shape !== "icon") && children}
    </button>
  )
}

export default forwardRef(Button)
