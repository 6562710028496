import clsx from "clsx"
import { Handle, Position, NodeProps } from "reactflow"

export enum NodeType {
  DESTINATION = "target",
  SOURCE = "source"
}

interface Props extends NodeProps {
  data: {
    title: string
    type: NodeType
    isWideTheme: boolean
    hide?: boolean
    onlySecondHandle?: boolean
    resource: api.link.Sender | api.link.Receiver
  }
}

const CustomNode = ({ data }: Props) => {
  const isActive = data.resource?.status?.state === "active"

  const generateHandleClassName = (index: number) =>
    clsx(
      "radius-full",
      data.isWideTheme ? "!top-auto h-2.5 w-2.5 border border-white" : "h-2 w-2",
      data?.resource?.streams?.[index]?.status?.state === "active" ||
        data?.resource?.streams?.[index]?.status?.state === "standby"
        ? "!bg-green-600"
        : data?.resource?.streams?.[index]?.status?.state === "disabled"
          ? "!bg-grey-500"
          : "!bg-red-500",
      data.hide && "!invisible",
      {
        ...(data.isWideTheme
          ? {
              "!bottom-1": index === 1,
              "!bottom-5": index === 0 || data.onlySecondHandle
            }
          : {
              "!left-2.5": data.type === NodeType.DESTINATION,
              "!right-2.5": data.type !== NodeType.DESTINATION,
              "!top-[1.0625rem]": index === 0,
              "!top-[2.3125rem]": index === 1 || data.onlySecondHandle
            })
      }
    )

  return (
    <>
      {!!data?.resource?.streams?.[0] && !data.onlySecondHandle && (
        <Handle
          className={generateHandleClassName(0)}
          id="A"
          position={data.type === NodeType.DESTINATION ? Position.Left : Position.Right}
          type={data.type}
        />
      )}
      {(!!data?.resource?.streams?.[1] || data.onlySecondHandle) && (
        <Handle
          className={generateHandleClassName(data.onlySecondHandle ? 0 : 1)}
          id="B"
          position={data.type === NodeType.DESTINATION ? Position.Left : Position.Right}
          type={data.type}
        />
      )}
      <div
        className={clsx(
          "z-10 flex flex-col",
          data.isWideTheme
            ? "w-[8.5625rem] border border-[3px] bg-grey-600 radius-sm"
            : "w-[8.25rem] border border-2 bg-grey-300 pb-md pt-xs radius-sm",
          isActive ? "border-yellow-300" : data.isWideTheme ? "border-grey-600" : "border-grey-300",
          data.type === NodeType.DESTINATION && !data.isWideTheme && "items-end pl-xs pr-sm",
          data.type === NodeType.SOURCE && !data.isWideTheme && "pl-sm pr-xs",
          data.hide && "invisible"
        )}
      >
        {data.isWideTheme ? (
          <>
            <div
              className={clsx(
                "w-full bg-grey-800 px-sm py-2xs text-xs text-white radius-t-xs",
                isActive ? "border-yellow-300" : "border-grey-600"
              )}
            >
              {data.title || " "}
            </div>
            <div
              className={clsx(
                "h-full w-full bg-grey-600 p-sm text-xs text-grey-300 radius-b-sm",
                isActive ? "border-yellow-300" : "border-grey-600"
              )}
            >
              {data.resource?.status?.state || " "}
            </div>
          </>
        ) : (
          <div className={clsx("flex h-full flex-col text-xs")}>
            <span className="font-medium">{data.title || " "}</span>
            <span className="italic text-grey-600">{data.resource?.status?.state || " "}</span>
          </div>
        )}
      </div>
    </>
  )
}

export default CustomNode
