import { FC, ReactNode } from "react"
import Title3 from "src/components/base/typography/Title3"
import Panel from "src/components/panels/Panel"

interface Props {
  children: ReactNode
  title?: string
}

const StoryPanel: FC<Props> = ({ children, title }) => (
  <Panel as="section">
    {title && <Title3 className="mb-4 dark:text-grey-100">{title}</Title3>}
    {children}
  </Panel>
)

export default StoryPanel
