import { Navigate, useLocation } from "@tanstack/react-location"

const NoTrailingSlash = () => {
  const { search, hash, pathname, key } = useLocation().current

  if (pathname !== "/" && pathname.endsWith("/")) {
    return <Navigate key={key} hash={hash} replace search={search} to={pathname.slice(0, -1)} />
  }

  return null
}

export default NoTrailingSlash
