import clsx from "clsx"
import { useState, useEffect, FC } from "react"
import ReactFlow, { Node } from "reactflow"
import "reactflow/dist/base.css"
import "./LinkRedundancyFlow.scss"
import { useStaticNameReceivers, useStaticNameSenders } from "src/App/link/hooks/naming"
import { SimpleDestination } from "src/queries/linkDestinations"
import { SimpleSource } from "src/queries/linkSources"
import CustomEdge from "./CustomEdge"
import CustomNode, { NodeType } from "./CustomNode"

const edgeTypes = {
  CustomEdge
}

const nodeTypes = {
  CustomNode
}

interface Props {
  isWideTheme?: boolean
  onlySource?: boolean
  source: api.link.SourceResponse | SimpleSource
  destination: api.link.DestinationResponse | SimpleDestination
  className?: string
}

const LegacyLinkRedundancyFlow: FC<Props> = ({
  isWideTheme = false,
  onlySource = false,
  source,
  destination,
  className
}) => {
  const [nodes, setNodes] = useState<any>([])
  const [edges, setEdges] = useState<any>([])

  const namedSenders = useStaticNameSenders(source)
  const namedReceivers = useStaticNameReceivers(destination)

  useEffect(() => {
    const x2ndCol = isWideTheme ? 520 : 270
    const y2ndCol = isWideTheme ? 115 : 88

    const nodeList: Node[] = []
    const edgeLinkParam: {
      A?: { source: string; sourceHandle: string }
      B?: { source: string; sourceHandle: string }
    } = {}

    namedSenders.forEach((sender, senderIndex) => {
      nodeList.push({
        data: {
          isWideTheme,
          resource: sender,
          title: sender.name,
          type: NodeType.SOURCE
        },
        id: `${senderIndex + 1}`,
        position: senderIndex === 0 ? { x: 0, y: 0 } : { x: 0, y: y2ndCol },
        type: "CustomNode"
      })
      const nobodySelected = !sender.streams?.find(stream => stream.status?.selected)
      sender.streams?.forEach((inputStream, inputStreamIndex) => {
        // default case
        if (
          nobodySelected &&
          (senderIndex === 0 || (senderIndex === 1 && source.availabilityMode === "dual_link"))
        ) {
          const index =
            senderIndex === 1 && source.availabilityMode === "dual_link"
              ? senderIndex
              : inputStreamIndex
          const link = index === 0 ? "A" : "B"
          edgeLinkParam[link] = {
            source: `${senderIndex + 1}`,
            sourceHandle: inputStreamIndex === 0 ? "A" : "B"
          }
        } else if (inputStream?.status?.selected) {
          edgeLinkParam[inputStream.link] = {
            source: `${senderIndex + 1}`,
            sourceHandle: inputStreamIndex === 0 ? "A" : "B"
          }
        }
      })
    })
    namedReceivers.forEach((receiver, receiverIndex) => {
      const link = receiverIndex === 0 ? "A" : "B"
      nodeList.push({
        data: {
          hide: onlySource,
          isWideTheme,
          onlySecondHandle:
            edgeLinkParam?.[link]?.sourceHandle === "B" &&
            edgeLinkParam?.[link]?.source === `${receiverIndex + 1}` &&
            source.availabilityMode !== "single_link_with_failover",
          resource: receiver,
          title: receiver.name,
          type: NodeType.DESTINATION
        },
        id: `${receiverIndex + 3}`,
        position: {
          x: x2ndCol,
          y:
            namedReceivers.length === 1 && namedSenders.length === 2
              ? y2ndCol / 2
              : y2ndCol * receiverIndex
        },
        type: "CustomNode"
      })
    })
    setNodes(nodeList)
    setEdges([
      {
        data: {
          active: destination.status?.linksStates?.linkA === "active",
          isWideTheme,
          label: destination.availabilityMode === "dual_link" ? "A" : "",
          onlySource
        },
        ...edgeLinkParam["A"],
        id: "linkA",
        target: "3",
        type: "CustomEdge"
      },
      destination.availabilityMode === "dual_link" && {
        data: {
          active: destination.status?.linksStates?.linkB === "active",
          isWideTheme,
          label: "B",
          onlySource
        },
        ...edgeLinkParam["B"],
        id: "linkB",
        target: "4",
        type: "CustomEdge"
      }
    ])
  }, [isWideTheme, onlySource, namedSenders, namedReceivers, source, destination])

  return (
    <div
      className={clsx(
        className,
        "w-[25.3125rem] min-w-[25.3125rem]",
        isWideTheme ? "h-56" : namedSenders.length > 1 ? "h-40" : "h-[4.5rem]"
      )}
    >
      <ReactFlow
        autoPanOnConnect={false}
        autoPanOnNodeDrag={false}
        className="!cursor-default"
        connectOnClick={false}
        edges={edges}
        edgesFocusable={false}
        edgesUpdatable={false}
        edgeTypes={edgeTypes}
        elementsSelectable={false}
        nodes={nodes}
        nodesConnectable={false}
        nodesDraggable={false}
        nodesFocusable={false}
        nodeTypes={nodeTypes}
        panOnDrag={false}
        panOnScroll={false}
        preventScrolling={false}
        selectionOnDrag={false}
        selectNodesOnDrag={false}
        zoomOnDoubleClick={false}
        zoomOnPinch={false}
        zoomOnScroll={false}
      />
    </div>
  )
}

export default LegacyLinkRedundancyFlow
