import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import { captureException, setUser } from "@sentry/browser"
import { FC, ReactNode, useEffect } from "react"
import Loader from "src/components/base/misc/Loader"

interface Props {
  children: ReactNode
}

const AuthenticationRequired: FC<Props> = ({ children }) => {
  const { error, isLoading, user } = useAuth0()

  useEffect(() => {
    if (user) setUser({ email: user.email })
  }, [user])

  useEffect(() => {
    if (error) captureException(error)
  }, [error])

  if (isLoading) <Loader isCover />

  return <>{children}</>
}

// eslint-disable-next-line react/display-name
export default withAuthenticationRequired(AuthenticationRequired, {
  onRedirecting: () => <Loader isCover />
})
