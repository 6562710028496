import clsx from "clsx"
import { FC, ReactNode } from "react"

export interface Props {
  className?: string
  children: ReactNode
}

const ModalContent: FC<Props> = ({ className, children }) => (
  <div
    className={clsx(
      "overflow-x-hidden bg-white shadow-xl radius-xs",
      "w-screen tablet:w-[calc(100vw-2rem)]",
      className
    )}
  >
    {children}
  </div>
)

export default ModalContent
