export const getAtCircularIndex = <Item>(arr: Item[], i: number): Item => {
  if (arr.length === 0) throw new Error("Array is empty")
  return arr[((i % arr.length) + arr.length) % arr.length]!
}

// Filter tools: https://stackoverflow.com/a/58110124/1202385
export const nonNullable = <T>(value: T): value is NonNullable<T> =>
  value !== null && value !== undefined

type Truthy<T> = T extends false | "" | 0 | null | undefined | void ? never : T // from lodash
export const truthy = <T>(value: T): value is Truthy<T> => !!value
