import { useLocation } from "@tanstack/react-location"
import { ReactElement, lazy, useEffect, useState } from "react"
import Loader from "src/components/base/misc/Loader"
import { AccessDeniedError, NotFoundError } from "src/helpers/errors"
import { ApiError } from "src/services/api"

const AccessDenied = lazy(() => import("src/components/oopsies/AccessDenied"))
const NotFound = lazy(() => import("src/components/oopsies/NotFound"))
const UserNotFound = lazy(() => import("src/components/oopsies/UserNotFound"))

interface Props {
  error: any
  resetErrorBoundary?: () => void
}

const ErrorFallback = ({ error, resetErrorBoundary }: Props): ReactElement | null => {
  const current = useLocation().current
  const [initialHref] = useState(current.href)

  useEffect(() => {
    if (current.href !== initialHref && resetErrorBoundary) resetErrorBoundary()
  }, [current.href]) // eslint-disable-line react-hooks/exhaustive-deps

  // Custom API error pages
  if (error instanceof ApiError) {
    const [apiPath] = error.uri.split("?")

    if (error.status === 404 && apiPath === "/users/me/") {
      return <UserNotFound />
    }
  }

  // Generic access denied page
  if (error instanceof AccessDeniedError || (error instanceof ApiError && error.status === 403)) {
    return <AccessDenied />
  }

  // Generic not found page
  if ((error instanceof ApiError && error.status === 404) || error instanceof NotFoundError) {
    return (
      <NotFound
        paragraphs={["Some of the content required on this page could not be found."]}
        title="Not found"
      />
    )
  }

  // Generic error page
  return <Loader error={error} isCover />
}

export default ErrorFallback
