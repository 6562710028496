import { faGlobe } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import clsx from "clsx"
import { isEmpty } from "lodash-es"
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from "reactflow"
import { SVGPathData } from "svg-pathdata"
import colors from "src/styles/colors"

export const PATH_TYPE = {
  BOTTOM_TO_TOP: "bottom_to_top",
  STRAIGHT_LINE: "straight_line",
  TOP_TO_BOTTOM: "top_to_bottom"
}

const CustomEdge = ({
  data,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd
}: EdgeProps) => {
  const [edgePath] = getSmoothStepPath({
    borderRadius: data.isWideTheme ? 25 : 10,
    centerX: data.isWideTheme ? (targetX - sourceX) / 2 : targetX - sourceX,
    sourcePosition,
    sourceX,
    sourceY,
    targetPosition,
    targetX,
    targetY
  })
  const pathData = new SVGPathData(edgePath)

  // Fix curve for "full" theme
  if (
    data.isWideTheme &&
    (data.pathType === PATH_TYPE.TOP_TO_BOTTOM || data.pathType === PATH_TYPE.BOTTOM_TO_TOP)
  ) {
    pathData.commands.splice(2, 1)
    if (data.pathType === PATH_TYPE.BOTTOM_TO_TOP) {
      if (pathData.commands[2] && "y" in pathData.commands[2]) {
        pathData.commands[2].y -= 20
      }
    } else {
      if (pathData.commands[2] && "y" in pathData.commands[2]) {
        pathData.commands[2].y += 20
      }
    }
    if (pathData.commands[1] && "x" in pathData.commands[1]) {
      pathData.commands[1].x -= 10
    }
  }

  if (data.onlySource) {
    pathData.commands.splice(pathData.commands.length - 2, 1)
    const lastPoint = pathData.commands[pathData.commands.length - 1]
    if (lastPoint && "x" in lastPoint) {
      lastPoint.x = (targetX + sourceX) / 2
    }
  }

  const lineStyle = {
    stroke: colors.yellow["300"],
    strokeWidth: data.isWideTheme ? 4 : 3,
    zIndex: 20,
    ...(!data.active && { strokeOpacity: 0.5 })
  }

  const haveLabel = !isEmpty(data.label)

  return (
    <>
      <BaseEdge markerEnd={markerEnd} path={pathData.encode()} style={lineStyle} />
      <EdgeLabelRenderer>
        <div
          className={clsx(
            "nodrag nopan relative flex items-center text-yellow-300 radius-full",
            haveLabel ? (data.isWideTheme ? "pr-xs" : "pr-2xs") : "w-8 justify-center",
            data.isWideTheme
              ? "bg-grey-700 p-2xs text-base"
              : "h-[1.125rem] bg-grey-900 p-0.5 text-xs font-bold"
          )}
          style={{
            position: "absolute",
            transform: `translate(0, -50%) translate(${(targetX + sourceX) / 2.15}px,${targetY}px)`,
            zIndex: 30
          }}
        >
          <FontAwesomeIcon
            aria-hidden
            className={clsx(data.isWideTheme ? "h-6" : "h-[0.875rem]")}
            icon={faGlobe}
          />
          {haveLabel && (
            <span className={clsx(data.isWideTheme ? "ml-2xs" : "ml-0.5")}>{data.label}</span>
          )}
          {!data.active && (
            <span className="absolute left-0 top-0 h-full w-full bg-white opacity-50 radius-full">
              {" "}
            </span>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  )
}

export default CustomEdge
