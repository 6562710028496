import clsx from "clsx"
import { Fragment } from "react"
import { useForm } from "react-hook-form"
import Button from "src/components/base/buttons/Button"
import Input from "src/components/base/forms/Input"
import Icon, { iconCommonNames } from "src/components/base/icon/Icon"
import { useToastManager } from "src/components/hooks"
import StoryPanel from "../../tools/StoryPanel"

const IconsStory = () => {
  const form = useForm<{ size: "16" | "20" | "24" }>({ defaultValues: { size: "24" } })
  const watchSize = Number(form.watch("size")) as 16 | 20 | 24
  const toastManager = useToastManager()
  const onIconClick = (icon: string, type: "solid" | "regular", size: 16 | 20 | 24) => {
    navigator.clipboard.writeText(`<Icon icon="${icon}" type="${type}" size={${size}} />`)
    toastManager.success("Copied to clipboard")
  }
  return (
    <StoryPanel title="Icons">
      <form noValidate>
        <span className="font-medium">Size</span>
        <fieldset className="mt-2">
          {["16", "20", "24"].map(size => (
            <Fragment key={size}>
              <Input {...form.register("size")} id={`select-${size}`} type="radio" value={size} />
              <label htmlFor={`select-${size}`}>{size}</label>
            </Fragment>
          ))}
        </fieldset>

        <div className="mt-8 grid grid-cols-[repeat(auto-fill,_6.25rem)] gap-md">
          {iconCommonNames.map(icon => (
            <div key={icon} className="flex flex-col items-center">
              <div
                className={clsx(
                  "flex h-16 w-[6.25rem] items-center justify-center gap-sm",
                  "border-2 border-grey-400 p-sm radius-md dark:border-grey-700"
                )}
              >
                <Button
                  className="h-fit border-none !p-0"
                  color="tertiary"
                  type="button"
                  onClick={() => onIconClick(icon, "regular", watchSize)}
                >
                  <Icon icon={icon} size={watchSize} type="regular" />
                </Button>
                <Button
                  className="h-fit !w-fit !p-0"
                  color="tertiary"
                  type="button"
                  onClick={() => onIconClick(icon, "solid", watchSize)}
                >
                  <Icon icon={icon} size={watchSize} type="solid" />
                </Button>
              </div>
              <span className="text-xs text-grey-800 dark:text-grey-300">{icon}</span>
            </div>
          ))}
        </div>
      </form>
    </StoryPanel>
  )
}

export default IconsStory
