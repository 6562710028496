import { clsx } from "clsx"
import { FC } from "react"

interface Props {
  color?: "primary" | "secondary" | "error"
  className?: string
  vertical?: boolean
}

const colorsToCls = {
  error: "bg-red-800",
  primary: "bg-grey-300",
  secondary: "bg-grey-500"
}

const Divider: FC<Props> = ({ color = "primary", className, vertical }) => {
  return <hr className={clsx(colorsToCls[color], vertical && "h-auto w-px", className)} />
}

export default Divider
