import { useEffect, useState } from "react"

export const useCatchupBaseUrl = (pool: api.ott.Pool) => {
  return `https://${pool.catchupDomain}`
}

export const useDocumentTitle = (title?: string) => {
  useEffect(() => {
    document.title = title || "Quortex I/O"
  }, [title])
}

export const useElementRect = () => {
  const [ref, setRef] = useState<null | HTMLElement>(null)
  const [rect, setRect] = useState<DOMRect>()

  useEffect(() => {
    const updateRect = () => {
      if (ref) {
        setRect(ref.getBoundingClientRect())
      }
    }

    // Initialize rect
    updateRect()

    // Observe size changes
    const resizeObserver = new ResizeObserver(updateRect)
    if (ref) {
      resizeObserver.observe(ref)
    }

    // Cleanup
    return () => resizeObserver.disconnect()
  }, [ref])

  return { rect, setRef }
}
