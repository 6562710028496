import { useAuth0 } from "@auth0/auth0-react"
import { useMemo } from "react"
import {
  jsonGet,
  jsonPatch,
  jsonPut,
  jsonPost,
  jsonDelete,
  uploadFile,
  ApiType,
  Headers,
  downloadFile
} from "src/services/api"

export const useApi = (api: ApiType) => {
  const { getAccessTokenSilently } = useAuth0()
  return useMemo(
    () => ({
      downloadFile: async (uri: string, options?: { headers?: Headers; signal?: AbortSignal }) =>
        downloadFile(uri, await getAccessTokenSilently(), api, options),
      jsonDelete: async <TData>(uri: string, headers = {}) =>
        jsonDelete<TData>(uri, await getAccessTokenSilently(), headers, api),
      jsonGet: async <TData>(uri: string, options?: { headers?: Headers; signal?: AbortSignal }) =>
        jsonGet<TData>(uri, await getAccessTokenSilently(), api, options),
      jsonPatch: async <TData>(uri: string, body: {}, headers = {}) =>
        jsonPatch<TData>(uri, body, await getAccessTokenSilently(), headers, api),
      jsonPost: async <TData>(uri: string, body: {}, headers = {}) =>
        jsonPost<TData>(uri, body, await getAccessTokenSilently(), headers, api),
      jsonPut: async <TData>(uri: string, body: {}, headers = {}) =>
        jsonPut<TData>(uri, body, await getAccessTokenSilently(), headers, api),
      uploadFile: async <TData>(
        uri: string,
        file: Blob,
        options?: {
          onProgress?: (progressRate: number) => void
          onXMLHttpRequest?: (request: XMLHttpRequest) => void
        }
      ) => uploadFile<TData>(uri, file, await getAccessTokenSilently(), options)
    }),
    [api, getAccessTokenSilently]
  )
}
