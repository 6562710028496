import clsx from "clsx"
import { FC, HTMLAttributes, PropsWithChildren } from "react"

interface Props extends HTMLAttributes<HTMLHeadingElement>, PropsWithChildren {}

const Title2: FC<Props> = ({ children, className, ...attrs }) => (
  <h2 className={clsx("leading-[1.125] typo-2xl-medium tablet:text-size-2", className)} {...attrs}>
    {children}
  </h2>
)
export default Title2
