import { useDeleteQuery, useGetQuery, usePatchQuery, usePostQuery, useRouteParams } from "./helpers"
import { ApiKeyParams, OrgParams } from "./types"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /organization/api_key/?org=${orgUuid} */
export const getOrganizationApiKeyListUrl = ({ orgUuid }: Required<OrgParams>) =>
  `/organization/api_key/?org=${orgUuid}`

/** @returns /organization/api_key/${apiKeyUuid}/?org=${orgUuid} */
export const getOrganizationApiKeyUrl = ({ orgUuid, apiKeyUuid }: Required<ApiKeyParams>) =>
  `/organization/api_key/${apiKeyUuid}/?org=${orgUuid}`

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const useOrganizationApiKeyList = (params?: OrgParams) => {
  const mergedParams = useRouteParams(params)
  const listUrl = getOrganizationApiKeyListUrl(mergedParams)

  return useGetQuery<api.user.PaginatedApiKeyList>("user", listUrl)
}

export const useOrganizationApiKey = (params?: ApiKeyParams) => {
  const mergedParams = useRouteParams(params)
  const url = getOrganizationApiKeyUrl(mergedParams)

  return useGetQuery<api.user.ApiKey>("user", url)
}

export const useAddOrganizationApiKey = (params?: OrgParams) => {
  const mergedParams = useRouteParams(params)
  const listUrl = getOrganizationApiKeyListUrl(mergedParams)

  type TRequest = DeepPartial<api.user.ApiKey>
  type TResponse = api.user.ApiKey

  return usePostQuery<TRequest, TResponse>("user", listUrl, [listUrl])
}

export const useUpdateOrganizationApiKey = (params?: ApiKeyParams) => {
  const mergedParams = useRouteParams(params)
  const url = getOrganizationApiKeyUrl(mergedParams)
  const listUrl = getOrganizationApiKeyListUrl(mergedParams)

  type TRequest = DeepPartial<api.user.ApiKey>
  type TResponse = api.user.ApiKey

  return usePatchQuery<TRequest, TResponse>("user", url, [listUrl])
}

export const useDeleteOrganizationApiKey = (params?: ApiKeyParams) => {
  const mergedParams = useRouteParams(params)
  const url = getOrganizationApiKeyUrl(mergedParams)
  const listUrl = getOrganizationApiKeyListUrl(mergedParams)

  return useDeleteQuery("user", url, [listUrl])
}
