const legacyColors = {
  "blue-rule": {
    300: "#e4ebfe",
    600: "#5783f7",
    700: "#2555d4",
    800: "#294aa0",
    900: "#202988"
  },
  danger: "#e53e3e",
  info: "#2f80ed",
  link: {
    600: "#3273dc",
    700: "#0053b6"
  },
  "red-orange": {
    600: "#ff470f"
  },
  success: "#29a85f",
  warning: "#e26a00"
}

const colors = {
  ...legacyColors,
  black: "#000",
  blue: {
    50: "#eff6ff",
    100: "#dbeafe",
    200: "#bfdbfe",
    300: "#93c5fd",
    400: "#60a5fa",
    500: "#0074e0",
    600: "#2563eb",
    700: "#1d4ed8",
    800: "#1e40af",
    900: "#1e3a8a"
  },
  current: "currentcolor",
  green: {
    50: "#e8f5e9",
    100: "#c8e6c9",
    200: "#a5d6a7",
    300: "#81c784",
    400: "#66bb6a",
    500: "#4caf50",
    600: "#43a047",
    700: "#388e3c",
    800: "#2e7d32",
    900: "#1b5e20"
  },
  grey: {
    50: "#f9fcff",
    100: "#f7fafc",
    200: "#edf2f7",
    300: "#e2e8f0",
    400: "#cbd5e0",
    500: "#a0aec0",
    600: "#718096",
    700: "#4a5568",
    800: "#2d3748",
    900: "#1a202c"
  },
  orange: {
    50: "#fff3e0",
    100: "#ffe0b2",
    200: "#ffcc80",
    300: "#ffb74d",
    400: "#ffa726",
    500: "#ff9800",
    600: "#fb8c00",
    700: "#f57c00",
    800: "#ef6c00",
    900: "#e65100"
  },
  pink: {
    50: "#fef6f8",
    100: "#fee5ea",
    200: "#fccfd9",
    300: "#faa7bb",
    400: "#f67697",
    500: "#f05e88",
    600: "#da2461",
    700: "#b81852",
    800: "#9a174a",
    900: "#841746"
  },
  purple: {
    50: "#f7f5ff",
    100: "#f2efff",
    200: "#e6dfff",
    300: "#cdc0ff",
    400: "#b3a0ff",
    500: "#9a81ff",
    600: "#8161ff",
    700: "#6149bf",
    800: "#413180",
    900: "#201840"
  },
  red: {
    50: "#ffebee",
    100: "#ffcdd2",
    200: "#ef9a9a",
    300: "#e57373",
    400: "#ef5350",
    500: "#f44336",
    600: "#e53935",
    700: "#d32f2f",
    800: "#c62828",
    900: "#b71c1c"
  },
  teal: {
    50: "#f3fcfb",
    100: "#e7f9f8",
    200: "#cff3f1",
    300: "#9fe7e3",
    400: "#6fdad6",
    500: "#3fcec8",
    600: "#14aaa3",
    700: "#0b928c",
    800: "#08615d",
    900: "#04312f"
  },
  transparent: "transparent",
  white: "#fff",
  yellow: {
    50: "#fffaeb",
    100: "#fff2c6",
    200: "#ffe288",
    300: "#ffcf4f",
    400: "#ffc020",
    500: "#ffad15",
    600: "#f59f00",
    700: "#e08e00",
    800: "#cc7e00",
    900: "#bd6e00"
  }
}

export default colors
