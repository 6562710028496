/* eslint-disable sort-keys-fix/sort-keys-fix */
import { Navigate, Outlet } from "@tanstack/react-location"
import { useSuspenseQuery } from "@tanstack/react-query"
import { lazy } from "react"
import ApiKeys from "src/App/settings/App/General/ApiKeys"
import { useOrganizationMember } from "src/queries/organizationMembers"
import { useMe } from "src/queries/users"

// Misc
const App = lazy(() => import("./App"))
const NotFound = lazy(() => import("src/components/oopsies/NotFound"))

// General
const General = lazy(() => import("./App/General"))
const Overview = lazy(() => import("./App/General/Overview"))
const Members = lazy(() => import("./App/General/Members"))
const Billing = lazy(() => import("./App/General/Billing"))
const AddApiKey = lazy(() => import("./App/General/ApiKeys/AddApiKey"))
const EditApiKey = lazy(() => import("./App/General/ApiKeys/EditApiKey"))
const SSO = lazy(() => import("./App/General/SSO"))
const SSOConfigure = lazy(() => import("./App/General/SSO/SSOConfigure"))
const ServiceAndSupport = lazy(() => import("./App/General/ServiceAndSupport"))

// Link
const Link = lazy(() => import("./App/Link"))
const LinkMembers = lazy(() => import("./App/Link/Members"))
const LinkUsage = lazy(() => import("./App/Link/Usage"))

// Play
const Play = lazy(() => import("./App/Play"))
const PlayMembers = lazy(() => import("./App/Play/Members"))
const PlayUsage = lazy(() => import("./App/Play/Usage"))
const PoolUsage = lazy(() => import("./App/Play/PoolUsage"))
const DRMProviders = lazy(() => import("./App/Play/DRMProviders"))
const AddDRMProvider = lazy(() => import("./App/Play/DRMProviders/AddDRMProvider"))
const EditDRMProvider = lazy(() => import("./App/Play/DRMProviders/EditDRMProvider"))

const RedirectSettingsHome = () => {
  const { data: me } = useSuspenseQuery(useMe())
  const { data: memberMe } = useSuspenseQuery(useOrganizationMember({ userUuid: me.uuid }))

  if (memberMe.isAdmin) {
    return <Navigate replace to="general" />
  }

  if (memberMe.link.role === "captain") {
    return <Navigate replace to="link" />
  }

  if (memberMe.play.role === "captain") {
    return <Navigate replace to="play" />
  }

  return <NotFound />
}

export const settingsRoutes = {
  path: "settings",
  element: <App />,
  children: [
    { path: "/", element: <RedirectSettingsHome /> },
    {
      path: "general",
      element: <General />,
      children: [
        { path: "/", element: <Navigate replace to="overview" /> },
        {
          path: "api_keys",
          children: [
            { path: "/", element: <ApiKeys /> },
            { path: "new", element: <AddApiKey /> },
            { path: ":apiKeyUuid", element: <EditApiKey /> },
            { element: <NotFound /> }
          ]
        },
        {
          path: "billing",
          element: <Outlet />,
          children: [{ path: "/", element: <Billing /> }, { element: <NotFound /> }]
        },
        { path: "members", element: <Members /> },

        { path: "overview", element: <Overview /> },
        {
          path: "sso",
          children: [
            { path: "/", element: <SSO /> },
            { path: "configure", element: <SSOConfigure /> },
            { element: <NotFound /> }
          ]
        },
        { path: "service", element: <ServiceAndSupport /> },
        { element: <NotFound /> }
      ]
    },
    {
      path: "link",
      element: <Link />,
      children: [
        { path: "/", element: <Navigate replace to="usage" /> },
        { path: "members", element: <LinkMembers /> },
        { path: "usage", element: <LinkUsage /> },
        { element: <NotFound /> }
      ]
    },
    {
      path: "play",
      element: <Play />,
      children: [
        { path: "/", element: <Navigate replace to="usage" /> },
        {
          path: "drm_providers",
          children: [
            { path: "/", element: <DRMProviders /> },
            { path: "new", element: <AddDRMProvider /> },
            { path: ":drmProviderUuid", element: <EditDRMProvider /> }
          ]
        },
        { path: "members", element: <PlayMembers /> },
        { path: "usage", element: <PlayUsage /> },
        { path: "pool_usage", element: <PoolUsage /> },
        { element: <NotFound /> }
      ]
    },
    { element: <NotFound /> }
  ]
}
