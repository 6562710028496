import { useSuspenseQuery } from "@tanstack/react-query"
import { clsx } from "clsx"
import Button from "src/components/base/buttons/Button"
import CardButton from "src/components/base/buttons/CardButton"
import Hypertext from "src/components/base/buttons/Hypertext"
import PageContainer from "src/components/base/containers/PageContainer"
import Icon from "src/components/base/icon/Icon"
import InfoPanel from "src/components/base/messages/InfoPanel"
import Divider from "src/components/base/misc/Divider"
import Title2 from "src/components/base/typography/Title2"
import Title3 from "src/components/base/typography/Title3"
import Panel from "src/components/panels/Panel"
import { formatNumericDateTime } from "src/helpers/formatters"
import { useDocumentTitle } from "src/hooks/misc"
import illustrationNoApiKey from "src/images/illustration-no-api-key.svg"
import { useOrganizationInfo } from "src/queries/organization"
import { useOrganizationApiKeyList } from "src/queries/organizationApiKeys"

const ApiKeys = () => {
  useDocumentTitle("API keys - General")

  const { data: organization } = useSuspenseQuery(useOrganizationInfo())
  const { data: apiKeys } = useSuspenseQuery({
    ...useOrganizationApiKeyList(),
    select: d => d.results
  })
  const maxOrgApiKeysNb = organization.limits.orgApiKeys || 0

  return (
    <PageContainer size="medium">
      <Panel>
        <Title2>Organization API keys</Title2>

        {apiKeys.length === 0 ? (
          <div className="mt-8 text-center">
            <img alt="" aria-hidden src={illustrationNoApiKey} />
            <p className="mt-12 text-grey-600">
              Organization API keys give unlimited access to the system on your behalf, you may want
              to use pool API keys instead for most operations.
            </p>
          </div>
        ) : (
          <>
            <InfoPanel className="mt-8" color="info">
              Organization API keys give unlimited access to the system on your behalf, you may want
              to use pool API keys instead for most operations.
            </InfoPanel>
            <div className="mt-4 space-y-4">
              {apiKeys.map(apiKey => (
                <CardButton
                  key={apiKey.uuid}
                  className="w-full"
                  icon="key"
                  to={apiKey.uuid}
                  type="link"
                >
                  <p>
                    {apiKey.name} at {formatNumericDateTime(new Date(apiKey.createdAt))}
                  </p>
                  <p className="text-info">
                    Last used:{" "}
                    {apiKey.lastUsedAt
                      ? formatNumericDateTime(new Date(apiKey.lastUsedAt))
                      : "Never"}
                  </p>
                </CardButton>
              ))}
            </div>
          </>
        )}

        <div className={clsx(apiKeys.length === 0 ? "mt-12 text-center" : "mt-6")}>
          <Button
            color="primary"
            disabled={apiKeys.length >= maxOrgApiKeysNb}
            title={
              apiKeys.length >= maxOrgApiKeysNb
                ? `You cannot create more than ${maxOrgApiKeysNb} organization keys`
                : undefined
            }
            to="new"
            type="link"
          >
            <Icon icon="plus" size={20} type="solid" />
            Add an API key
          </Button>
        </div>

        <Divider className="my-lg" />

        <Title3>Documentation</Title3>

        <InfoPanel className="mt-8" color="info" inline>
          Get started using our API{" "}
          <Hypertext
            external
            href="https://help.quortex.io/en/article/getting-started-with-the-api-1odf9uj/"
          >
            in this help article
          </Hypertext>
        </InfoPanel>
      </Panel>
    </PageContainer>
  )
}

export default ApiKeys
