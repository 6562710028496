import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import "src/polyfills/form-request-submit-polyfill" // for Safari, all versions 🙁
import App from "./App"

const container = document.getElementById("root")

if (container) {
  const root = createRoot(container)
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  )
}
