/* eslint-disable sort-keys-fix/sort-keys-fix */
import { DefaultGenerics, Outlet, Route } from "@tanstack/react-location"
import { lazy } from "react"
import Redirect from "src/components/navigation/Redirect"

// Misc
const App = lazy(() => import("./App"))

// Landings
const NotFound = lazy(() => import("src/components/oopsies/NotFound"))

// Main
const Main = lazy(() => import("./App/Main"))

// Pool
const Pool = lazy(() => import("./App/Main/Pool"))
const Status = lazy(() => import("./App/Main/Pool/status/Status"))
const Analytics = lazy(() => import("./App/Main/Pool/analytics/Analytics"))
const CloudSettings = lazy(() => import("./App/Main/Pool/settings/Settings"))
const PoolLocationForm = lazy(() => import("./App/Main/Pool/settings/Settings/PoolLocation"))
const AddPoolApiKey = lazy(() => import("./App/Main/Pool/settings/Settings/apiKeys/AddApiKey"))
const EditPoolApiKey = lazy(() => import("./App/Main/Pool/settings/Settings/apiKeys/EditApiKey"))
const EventLog = lazy(() => import("./App/Main/Pool/eventlog/EventLog"))
const AddInput = lazy(() => import("./components/features/inputs/AddInput"))
const AddInputDialogStep1 = lazy(() => import("./components/features/inputs/AddInputDialogStep1"))
const AddInputDialogStep2 = lazy(() => import("./components/features/inputs/AddInputDialogStep2"))
const AddInputDialogStep3 = lazy(() => import("./components/features/inputs/AddInputDialogStep3"))
const InputList = lazy(() => import("./App/Main/Pool/inputs/InputList"))
const ViewInput = lazy(() => import("./App/Main/Pool/inputs/ViewInput"))
const AddStream = lazy(() => import("./App/Main/Pool/inputs/AddStream"))
const EditStream = lazy(() => import("./App/Main/Pool/inputs/EditStream"))

const AddProcessing = lazy(() => import("./components/features/processing/AddProcessing"))
const AddProcessingDialogStep1 = lazy(
  () => import("./components/features/processing/AddProcessingDialogStep1")
)
const AddProcessingDialogStep2 = lazy(
  () => import("./components/features/processing/AddProcessingDialogStep2")
)
const EditProcessing = lazy(() => import("./components/features/processing/EditProcessing"))
const EditProcessingDialogStep1 = lazy(
  () => import("src/App/play/components/features/processing/EditProcessingDialogStep")
)
const ProcessingList = lazy(() => import("./App/Main/Pool/processing/ProcessingList"))

const AddPackaging = lazy(() => import("./components/features/packaging/AddPackaging"))
const AddPackagingDialogStep1 = lazy(
  () => import("./components/features/packaging/AddPackagingDialogStep1")
)
const AddPackagingDialogStep2 = lazy(
  () => import("./components/features/packaging/AddPackagingDialogStep2")
)
const EditPackaging = lazy(() => import("./components/features/packaging/EditPackaging"))
const EditPackagingDialogStep1 = lazy(
  () => import("src/App/play/components/features/packaging/EditPackagingDialogStep")
)
const PackagingList = lazy(() => import("./components/features/packaging/PackagingList"))

const OttEndpointList = lazy(() => import("./components/features/ottendpoints/OttEndpointList"))
const OttEndpointView = lazy(() => import("./components/features/ottendpoints/OttEndpointView"))
const PushEndpoints = lazy(() => import("./App/Main/Pool/pushendpoints/PushEndpoints"))
const AddRTMPPushEndpoint = lazy(() => import("./App/Main/Pool/pushendpoints/AddPushEndpoint"))
const ViewPushEndpoint = lazy(() => import("./App/Main/Pool/pushendpoints/ViewPushEndpoint"))
const AddRule = lazy(() => import("./App/Main/Pool/rules/AddRule"))
const EditRule = lazy(() => import("./App/Main/Pool/rules/EditRule"))
const Rules = lazy(() => import("./App/Main/Pool/rules/Rules"))
const RuleWrapper = lazy(() => import("./App/Main/Pool/rules/RuleWrapper"))

const Programs = lazy(() => import("./App/Main/Pool/catchup/Programs"))
const AddProgram = lazy(() => import("./App/Main/Pool/catchup/Programs/AddProgram"))
const EditProgram = lazy(() => import("./App/Main/Pool/catchup/Programs/EditProgram"))
const AddVodAsset = lazy(() => import("./App/Main/Pool/catchup/VodAssets/AddVodAsset"))
const AddDynamicEncryptionConfiguration = lazy(
  () =>
    import(
      "./components/features/encryption_configurations/dynamic/AddDynamicEncryptionConfiguration"
    )
)
const EditDynamicEncryptionConfiguration = lazy(
  () =>
    import(
      "./components/features/encryption_configurations/dynamic/EditDynamicEncryptionConfiguration"
    )
)
const AddStaticEncryptionConfiguration = lazy(
  () =>
    import(
      "./components/features/encryption_configurations/static/AddStaticEncryptionConfiguration"
    )
)
const EditStaticEncryptionConfiguration = lazy(
  () =>
    import(
      "./components/features/encryption_configurations/static/EditStaticEncryptionConfiguration"
    )
)

// Wizard
const Wizard = lazy(() => import("./App/Wizard"))
const WizardCloudSettings = lazy(() => import("./App/Wizard/cloudsettings/CloudSettings"))
const WizardDone = lazy(() => import("./App/Wizard/done/Done"))

const getAddInputChildrenRoutes = (isWizard: boolean) => [
  { path: "step1", element: <AddInputDialogStep1 isWizard={isWizard} /> },
  { path: "step2-1", element: <AddInputDialogStep2 isWizard={isWizard} streamIndex={1} /> },
  { path: "step2-2", element: <AddInputDialogStep2 isWizard={isWizard} streamIndex={2} /> },
  { path: "step3", element: <AddInputDialogStep3 isWizard={isWizard} /> }
]

const getAddPackagingRoute = (isWizard: boolean) => ({
  path: "packaging/new",
  element: <AddPackaging isWizard={isWizard} />,
  children: [
    {
      path: "step1/baseSettings",
      element: <AddPackagingDialogStep1 isWizard={isWizard} tab="baseSettings" />
    },
    {
      path: "step1/encryption",
      element: <AddPackagingDialogStep1 isWizard={isWizard} tab="encryption" />
    },
    {
      path: "step1/scte35",
      element: <AddPackagingDialogStep1 isWizard={isWizard} tab="scte35" />
    },
    {
      path: "step1/advanced",
      element: <AddPackagingDialogStep1 isWizard={isWizard} tab="advanced" />
    },
    {
      path: "step1/encryptions/dynamic/new",
      element: <AddDynamicEncryptionConfiguration />
    },
    {
      path: "step1/encryptions/dynamic/:encryptionUuid",
      element: <EditDynamicEncryptionConfiguration />
    },
    {
      path: "step1/encryptions/static/new",
      element: <AddStaticEncryptionConfiguration />
    },
    {
      path: "step1/encryptions/static/:encryptionUuid",
      element: <EditStaticEncryptionConfiguration />
    },
    {
      path: "step2",
      element: <AddPackagingDialogStep2 isWizard={isWizard} />
    }
  ]
})

const getEditPackagingRoute = (isWizard: boolean) => ({
  path: "packaging/:targetUuid",
  element: <EditPackaging isWizard={isWizard} />,
  children: [
    {
      path: "baseSettings",
      element: <EditPackagingDialogStep1 isWizard={isWizard} tab="baseSettings" />
    },
    {
      path: "encryption",
      element: <EditPackagingDialogStep1 isWizard={isWizard} tab="encryption" />
    },
    {
      path: "scte35",
      element: <EditPackagingDialogStep1 isWizard={isWizard} tab="scte35" />
    },
    {
      path: "advanced",
      element: <EditPackagingDialogStep1 isWizard={isWizard} tab="advanced" />
    },
    {
      path: "encryptions/dynamic/new",
      element: <AddDynamicEncryptionConfiguration />
    },
    {
      path: "encryptions/dynamic/:encryptionUuid",
      element: <EditDynamicEncryptionConfiguration />
    },
    {
      path: "encryptions/static/new",
      element: <AddStaticEncryptionConfiguration />
    },
    {
      path: "encryptions/static/:encryptionUuid",
      element: <EditStaticEncryptionConfiguration />
    }
  ]
})

const getAddProcessingRoute = (isWizard: boolean) => ({
  path: "processing/new",
  element: <AddProcessing isWizard={isWizard} />,
  children: [
    {
      path: "step1/video",
      element: <AddProcessingDialogStep1 isWizard={isWizard} tab="video" />
    },
    {
      path: "step1/audio",
      element: <AddProcessingDialogStep1 isWizard={isWizard} tab="audio" />
    },
    {
      path: "step1/subtitles",
      element: <AddProcessingDialogStep1 isWizard={isWizard} tab="subtitles" />
    },
    {
      path: "step2",
      element: <AddProcessingDialogStep2 isWizard={isWizard} />
    }
  ]
})

const getEditProcessingRoute = () => ({
  path: "processing/:processingUuid",
  element: <EditProcessing />,
  children: [
    {
      path: "video",
      element: <EditProcessingDialogStep1 tab="video" />
    },
    {
      path: "audio",
      element: <EditProcessingDialogStep1 tab="audio" />
    },
    {
      path: "subtitles",
      element: <EditProcessingDialogStep1 tab="subtitles" />
    },
    {
      path: "advanced",
      element: <EditProcessingDialogStep1 tab="advanced" />
    }
  ]
})

const getEditOttEndpointRoute = (isWizard: boolean) => ({
  path: "ottendpoints/:ottEndpointUuid",
  element: <Outlet />,
  children: [
    { path: "overview", element: <OttEndpointView tab="overview" /> },
    { path: "startover", element: <OttEndpointView tab="startover" /> },
    { path: "advanced", element: <OttEndpointView tab="advanced" /> }
  ]
})

export const playRoutes: Route<DefaultGenerics> = {
  path: "play",
  element: <App />,
  children: [
    {
      path: "pools",
      element: <Main />,
      children: [
        {
          path: ":poolUuid",
          element: <Pool />,
          children: [
            { path: "/", element: <Status /> },
            { path: "analytics", element: <Analytics /> },
            { path: "catchup/programs/new", element: <AddProgram /> },
            { path: "catchup/programs/:programUuid/assets/new", element: <AddVodAsset /> },
            { path: "catchup/programs/:programUuid", element: <EditProgram /> },
            { path: "catchup/programs", element: <Programs /> },
            { path: "eventlog", element: <EventLog /> },
            {
              path: "rules",
              element: <RuleWrapper />,
              children: [
                { path: "/", element: <Rules /> },
                { path: ":ruleType/new", element: <AddRule /> },
                { path: ":ruleType/:ruleUuid", element: <EditRule /> }
              ]
            },
            { path: "settings/apikeys/new", element: <AddPoolApiKey /> },
            { path: "settings/apikeys/:apiKeyUuid", element: <EditPoolApiKey /> },
            { path: "settings/poolLocation", element: <PoolLocationForm /> },
            { path: "settings", element: <CloudSettings /> },
            {
              path: "live",
              children: [
                { path: "inputs/:inputUuid/streams/new/:type", element: <AddStream /> },
                { path: "inputs/:inputUuid/streams/:streamUuid", element: <EditStream /> },
                {
                  path: "inputs/new",
                  element: <AddInput />,
                  children: getAddInputChildrenRoutes(false)
                },

                { path: "inputs/:inputUuid", element: <ViewInput /> },
                { path: "inputs", element: <InputList /> },
                getAddProcessingRoute(false),
                getEditProcessingRoute(),
                { path: "processing", element: <ProcessingList /> },
                getAddPackagingRoute(false),
                getEditPackagingRoute(false),
                { path: "packaging", element: <PackagingList /> },
                getEditOttEndpointRoute(false),
                { path: "ottendpoints", element: <OttEndpointList /> },
                { path: "pushendpoints/new/rtmp", element: <AddRTMPPushEndpoint /> },
                { path: "pushendpoints/:pushEndpointUuid", element: <ViewPushEndpoint /> },
                { path: "pushendpoints", element: <PushEndpoints /> }
              ]
            },
            { element: <NotFound /> }
          ]
        }
      ]
    },
    {
      path: "wizard/:poolUuid",
      element: <Wizard />,
      children: [
        { path: "properties", element: <WizardCloudSettings /> },
        {
          path: "input",
          element: <AddInput isWizard />,
          children: getAddInputChildrenRoutes(true)
        },
        getAddProcessingRoute(true),
        getAddPackagingRoute(true),
        getEditPackagingRoute(true),
        { path: "packaging", element: <PackagingList isWizard /> },
        getEditOttEndpointRoute(false),
        { path: "ottendpoints", element: <OttEndpointList isWizard /> },
        { path: "done", element: <WizardDone /> },
        {
          element: <NotFound />
        }
      ]
    },
    {
      path: "wizard",
      element: <Redirect to="new/properties" />
    },
    {
      element: <NotFound />
    }
  ]
}
